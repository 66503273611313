import React from 'react';
import { graphql } from 'gatsby';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

import ContentBlock from '../blocks/ContentBlock';
import { DefaultLayout } from '../layouts/Default';

const DatenschutzPage = ({ data, location }) => {
  const { site, content } = data;
  const { opengraph, metadata, contentblock } = content;
  const { siteMetadata } = site;
  return (
    <DefaultLayout>
      <ContentBlock data={contentblock} />
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default DatenschutzPage;

export const DatenschutzPageQuery = graphql`
  query DatenschutzPageQuery {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    content: cockpitRegionDatenschutz {
      id
      contentblock {
        title
        published
        text
        align
        columns
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630)
          }
        }
      }
    }
  }
`;
